import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layouts/Base"
import SEO from "../components/seo"
import Sidebar from "../components/Integrations/Sidebar"
import Player from "../components/VideoPopup/Player"

export const pageQuery = graphql`
  query BlogIndexQuery($limit: Int, $skip: Int) {
    allSanityPost(
      sort: { fields: publishedAt, order: DESC }
      skip: $skip
      limit: $limit
      filter: { showInSidebar: { eq: true } }
    ) {
      edges {
        node {
          slug {
            current
          }
          title
        }
      }
    }
  }
`

const BlogIndexPage = ({ data, pageContext: { page, totalPages } }) => {
  const posts = data.allSanityPost.edges

  return (
    <Layout>
      <SEO
        title="Find articles and tips on payment best practices"
        description="Are you a small services business or startup? We write articles on best practices, integrations and how to get started with recurring and one-time payments."
        ogType="blog"
      />

      <div className="max-w-5xl mx-auto px-6 py-10 lg:pb-16">
        <div className="md:grid md:grid-cols-12 md:gap-10">
          <Sidebar posts={posts} />
          <div className="md:col-span-8">
            <h1 className="text-2xl font-display text-black font-bold leading-8 md:text-4xl sm:leading-tight">
              Accept Payments & Sell Subscriptions Anywhere using Payhere
              Integrations
            </h1>

            <div className="mt-6">
              <Player youtubeId={`117UBx0hkoI`} />
            </div>

            <div className="prose mt-6">
              <p>
                <strong>Have a website? </strong>Embed payment forms using your
                site builder, or our customizable SDKs, or link to payment pages
                with simple buttons.
              </p>
              <p>
                <strong>Don't have a website?&nbsp;</strong>Use Storefronts to
                publish payment links for your business.
              </p>
              <p>
                <strong>
                  Using a no-code site or app builder like Bubble?{" "}
                </strong>
                Integrate Payhere to start collecting payments and selling
                memberships.
              </p>
              <p>
                <strong>Want to take payments elsewhere? </strong>Make payment
                links in Payhere, and share them with clients using the tools
                you already depend on.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogIndexPage
